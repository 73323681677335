import React from "react";


import bgImg from '../imgs/newSize.png'


export default function Home() {
  return (
    <div className="aspect-auto h-screen w-screen bg-gray-600">



      <div className="bg-contain bg-no-repeat bg-center w-11/12 h-5/6 ml-5" style={{ backgroundImage: `url(${bgImg})` }}>
      </div>


    </div>
  );
}