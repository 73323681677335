import React from 'react';
import './App.css';

import PortfolioContainer from './components/PortfolioContainer';



const App = () => <PortfolioContainer />

export default App;
