import React from "react";
export default function Contact() {
  return (
    <div id="contact" className="text-center" >
      <h1>Contact Me</h1>
      <ul>
        <li>Email: wdputnam42@gmail.com</li>
        <li>LinkedIn: <a href="https://www.linkedin.com/in/will-putnam-4b028527a/" >Click here</a> </li>
        <li>GitHub: <a href="https://github.com/Will-rd" >Click here</a> </li>
      </ul>

    </div>
  );
}